import React, { createContext, forwardRef } from 'react';
import { useCheckboxGroup } from '@react-native-aria/checkbox';
import { useCheckboxGroupState } from '@react-stately/checkbox';
import { useFormControlContext } from '@gluestack-ui/form-control';
export const CheckboxGroupContext = createContext(null);
const CheckboxGroup = (StyledCheckboxGroup) => forwardRef(({ children, ...props }, ref) => {
    const state = useCheckboxGroupState({
        ...props,
        validationState: props.isInvalid ? 'invalid' : 'valid',
    });
    const { groupProps } = useCheckboxGroup({
        ...props,
        'aria-label': props['aria-label'],
    }, 
    //@ts-ignore
    state);
    const formControlContext = useFormControlContext();
    return (<CheckboxGroupContext.Provider value={{ state: { ...formControlContext, ...state } }}>
        <StyledCheckboxGroup {...groupProps} {...props} ref={ref}>
          {children}
        </StyledCheckboxGroup>
      </CheckboxGroupContext.Provider>);
});
export default CheckboxGroup;

import React, { forwardRef } from 'react';
import { combineContextAndProps } from '@gluestack-ui/utils';
import { useFormControlContext } from './useFormControl';
import { mergeRefs } from '@gluestack-ui/utils';
const FormControlLabel = ({ Label: StyledFormControlLabel, LabelAstrick: StyledFormControlLabelAstrick, }) => forwardRef(({ children, ...props }, ref) => {
    const formControlContext = useFormControlContext();
    const { isRequired, ...combinedProps } = combineContextAndProps(formControlContext, props);
    const _ref = React.useRef(null);
    const mergedRef = mergeRefs([_ref, ref]);
    React.useEffect(() => {
        if (_ref.current) {
            // RN web doesn't support htmlFor for Label element yet
            if (props.htmlFor) {
                _ref.current.htmlFor = props.htmlFor;
            }
            else if (combinedProps?.id) {
                _ref.current.htmlFor = combinedProps.id;
            }
        }
    }, [combinedProps?.id, props.htmlFor]);
    return (<StyledFormControlLabel ref={mergedRef} {...combinedProps} id={combinedProps?.labelId}>
        {children}
        {isRequired && (<StyledFormControlLabelAstrick>*</StyledFormControlLabelAstrick>)}
      </StyledFormControlLabel>);
});
export default FormControlLabel;

import React, { forwardRef } from 'react';
const ChildPath = ({ element, fill, stroke: pathStroke }) => {
    const pathStrokeColor = pathStroke || '';
    const fillColor = fill || '';
    if (!element) {
        return null;
    }
    if (element.type === React.Fragment) {
        return element;
    }
    return React.cloneElement(element, {
        fill: fillColor ? fillColor : 'currentColor',
        stroke: pathStrokeColor,
    });
};
export function createIcon({ Root, path, d, ...initialProps }) {
    const IconTemp = forwardRef((props, ref) => {
        let children = path;
        if (d && (!path || Object.keys(path).length === 0)) {
            children = <path fill="currentColor" d={d}/>;
        }
        const finalProps = {
            ...initialProps,
            ...props,
        };
        const { stroke = 'currentColor', color, role = 'img', ...resolvedProps } = finalProps;
        let type = resolvedProps.type;
        if (type === undefined) {
            type = 'svg';
        }
        let colorProps = {};
        if (color) {
            colorProps = { ...colorProps, color: color };
        }
        if (stroke) {
            colorProps = { ...colorProps, stroke: stroke };
        }
        let sizeProps = {};
        let sizeStyle = {};
        if (type === 'font') {
            if (resolvedProps.sx) {
                sizeProps = { ...sizeProps, fontSize: resolvedProps?.sx?.h };
            }
            if (resolvedProps.size) {
                // sizeProps = { ...sizeProps, fontSize: resolvedProps?.size };
            }
        }
        return (<Root {...resolvedProps} {...colorProps} role={role} ref={ref} {...sizeProps} {...sizeStyle}>
        {React.Children.count(children) > 0 ? (<g>
            {React.Children.map(children, (child, i) => (<ChildPath key={child?.key ?? i} element={child} {...child?.props}/>))}
          </g>) : null}
      </Root>);
    });
    const Icon = IconTemp;
    return Icon;
}

// This is the main index file that will be included in build
import { Menu as MenuMain } from './Menu';
import { MenuItem } from './MenuItemStately';
import { MenuItemLabel } from './MenuItemLabel';
import { Separator } from './Separator';
const createMenu = ({ Root: StyledMenu, Item: StyledMenuItem, Label: StyledItemLabel, Backdrop: StyledBackdrop, AnimatePresence, Separator: StyledSeparator, }) => {
    const MenuTemp = MenuMain({
        StyledMenu,
        StyledMenuItem,
        StyledBackdrop,
        AnimatePresence,
        StyledSeparator,
    });
    MenuTemp.Item = MenuItem;
    MenuTemp.ItemLabel = MenuItemLabel(StyledItemLabel);
    MenuTemp.Separator = Separator;
    const Menu = MenuTemp;
    return Menu;
};
export { createMenu };

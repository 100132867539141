import React, { useMemo } from 'react';
import { TVView } from '@/components/themed';
import ContainerFilters from './ContainerFilters';
import ContainerAgentFilters from './ContainerAgentFilters';
import ContainerChannelsFilters from './ContainerChannelsFilters';
import { Filters } from '@/redux/slices/filters/interfaces';
import { Routes } from '@/routers/routes';
import ContainerLabelsFIlters from './ContainerLabelsFilters';

const ContainerAcordionsFilters = ({
  collapsed,
  activeRoute,
  routeName,
  handlePressedFilter,
  teamId
}: {
  collapsed: boolean;
  activeRoute: string;
  routeName: string;
  handlePressedFilter: (filter: Filters) => void;
  teamId: string;
}) => {
  const commonProps = useMemo(() => {
    return {
      collapsed,
      activeRoute,
      routeName,
      teamId
    };
  }, [collapsed, activeRoute, routeName, teamId]);

  return (
    <TVView>
      <ContainerFilters {...commonProps} handlePressedFilter={handlePressedFilter} />

      {routeName == Routes?.clientes && (
        <>
          <ContainerLabelsFIlters {...commonProps} handlePressedFilter={handlePressedFilter} />
          <ContainerAgentFilters {...commonProps} handlePressedFilter={handlePressedFilter} />
          <ContainerChannelsFilters {...commonProps} />
        </>
      )}
    </TVView>
  );
};

export default React.memo(ContainerAcordionsFilters, (prevProps, nextProps) => {
  return (
    prevProps.collapsed === nextProps.collapsed &&
    prevProps.activeRoute === nextProps.activeRoute &&
    prevProps.routeName === nextProps.routeName &&
    prevProps.handlePressedFilter === nextProps.handlePressedFilter &&
    prevProps.teamId === nextProps.teamId
  );
});

import React, { forwardRef } from 'react';
import { Platform } from 'react-native';
export function Divider(StyledDivider) {
    return forwardRef(({ children, ...props }, ref) => {
        const { orientation } = props;
        return (<StyledDivider ref={ref} {...props} aria-orientation={orientation} 
        //@ts-ignore web only role
        role={Platform.OS === 'web' ? 'separator' : undefined}>
          {children}
        </StyledDivider>);
    });
}

import React, { forwardRef } from 'react';
import { combineContextAndProps } from '@gluestack-ui/utils';
import { useFormControlContext } from './useFormControl';
const FormControlHelper = (StyledFormControlHelper) => forwardRef(({ children, ...props }, ref) => {
    const formControlContext = useFormControlContext();
    const combinedProps = combineContextAndProps(formControlContext, props);
    React.useEffect(() => {
        combinedProps?.setHasHelpText(true);
        return () => {
            combinedProps?.setHasHelpText(false);
        };
    });
    return (<StyledFormControlHelper ref={ref} {...combinedProps} id={combinedProps?.labelId}>
        {children}
      </StyledFormControlHelper>);
});
export default FormControlHelper;

import React from 'react';
import { useEffect } from 'react';
import { BackHandler, Platform } from 'react-native';
let keyboardDismissHandlers = [];
export const keyboardDismissHandlerManager = {
    push: (handler) => {
        keyboardDismissHandlers.push(handler);
        return () => {
            keyboardDismissHandlers = keyboardDismissHandlers.filter((h) => h !== handler);
        };
    },
    length: () => keyboardDismissHandlers.length,
    pop: () => {
        return keyboardDismissHandlers.pop();
    },
};
/**
 * Handles attaching callback for Escape key listener on web and Back button listener on Android
 */
export const useKeyboardDismissable = ({ enabled, callback }) => {
    React.useEffect(() => {
        let cleanupFn = () => { };
        if (enabled) {
            cleanupFn = keyboardDismissHandlerManager.push(callback);
        }
        else {
            cleanupFn();
        }
        return () => {
            cleanupFn();
        };
    }, [enabled, callback]);
    useBackHandler({ enabled, callback });
};
export function useBackHandler({ enabled, callback }) {
    useEffect(() => {
        if (Platform.OS === 'web') {
            const handleEscape = (e) => {
                if (e.key === 'Escape') {
                    callback();
                }
            };
            document?.body?.addEventListener?.('keyup', handleEscape);
            return () => {
                document?.body?.removeEventListener?.('keyup', handleEscape);
            };
        }
        else {
            const backHandler = () => {
                callback();
                return true;
            };
            if (enabled) {
                BackHandler.addEventListener('hardwareBackPress', backHandler);
            }
            else {
                BackHandler.removeEventListener('hardwareBackPress', backHandler);
            }
            return () => BackHandler.removeEventListener('hardwareBackPress', backHandler);
        }
    }, [enabled, callback]);
}

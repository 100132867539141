/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { forwardRef, useRef } from 'react';
import { useMenu, useMenuTrigger } from '@react-native-aria/menu';
import { useTreeState, useMenuTriggerState } from 'react-stately';
import { Popover } from './MenuPopover/Popover';
import { MenuItem } from './MenuItem';
import { OverlayAnimatePresence } from './MenuPopover/OverlayAnimatePresence';
import { useTypeSelect } from './useTypeSelect';
import { useControlledState } from '@react-stately/utils';
import { MenuContext } from './MenuContext';
import { mergeRefs } from '@gluestack-ui/utils';
export const Menu = ({ StyledMenu, StyledMenuItem, StyledBackdrop, AnimatePresence, StyledSeparator, }) => {
    return forwardRef(({ crossOffset, closeOnSelect, defaultIsOpen, isOpen: isOpenProp, onOpen, onClose, offset, placement = 'bottom start', shouldFlip = true, trigger, shouldOverlapWithTrigger, _experimentalOverlay = false, useRNModal = false, ...props }, ref) => {
        const [isOpenHover, setIsOpenHover] = React.useState(false);
        const [isOpen, setIsOpen] = useControlledState(isOpenProp, defaultIsOpen || isOpenHover, (isOpenValue) => {
            isOpenValue ? onOpen?.() : onClose?.();
        });
        const handleHover = () => {
            setIsOpenHover(true);
        };
        const handleClose = () => {
            setIsOpen(false);
        };
        const handleOpen = () => {
            setIsOpen(true);
        };
        const showBackdrop = React.useRef(false);
        const state = useMenuTriggerState({
            isOpen: isOpen,
            //@ts-ignore
            closeOnSelect: closeOnSelect,
            onOpenChange: (isOpenValue) => {
                setIsOpen(isOpenValue);
            },
            defaultOpen: defaultIsOpen,
        });
        const triggerRef = React.useRef(null);
        const { menuTriggerProps, menuProps } = useMenuTrigger({}, state, triggerRef);
        const updatedTrigger = () => {
            return trigger({
                ...menuTriggerProps,
                onPress: handleOpen,
                ref: triggerRef,
            });
        };
        if (_experimentalOverlay) {
            return (<MenuContext.Provider value={{ onClose: handleClose, showBackdrop }}>
            {updatedTrigger()}

            <MenuComponent {...menuProps} {...props} isOpen={state.isOpen} AnimatePresence={AnimatePresence} autoFocus={state.focusStrategy || true} onClose={() => state.close()} StyledMenu={StyledMenu} StyledMenuItem={StyledMenuItem} StyledSeparator={StyledSeparator} closeOnSelect={closeOnSelect} ref={ref}/>
          </MenuContext.Provider>);
        }
        return (<MenuContext.Provider value={{ onClose: handleClose, showBackdrop }}>
          {updatedTrigger()}
          <Popover placement={placement} triggerRef={triggerRef} state={state} shouldOverlapWithTrigger={shouldOverlapWithTrigger} crossOffset={crossOffset} offset={offset} shouldFlip={shouldFlip} StyledBackdrop={StyledBackdrop} useRNModal={useRNModal}>
            <MenuComponent {...menuProps} {...props} isOpen={state.isOpen} AnimatePresence={AnimatePresence} autoFocus={state.focusStrategy || true} onClose={() => state.close()} StyledMenu={StyledMenu} StyledMenuItem={StyledMenuItem} StyledSeparator={StyledSeparator} closeOnSelect={closeOnSelect} ref={ref}/>
          </Popover>
        </MenuContext.Provider>);
    });
};
const MenuComponent = forwardRef(({ StyledMenu, StyledMenuItem, StyledSeparator, AnimatePresence, isOpen, closeOnSelect, ...props }, ref) => {
    const state = useTreeState(props);
    const menuRef = useRef(null);
    const mergeRef = mergeRefs([menuRef, ref]);
    const { menuProps } = useMenu(props, state, menuRef);
    const { onClose, onOpen, selectionMode, onSelectChange, shouldFlip, children, placement, offset, crossOffset, trigger, StyledBackdrop, ...restProps } = props;
    const typeSelectProps = useTypeSelect(state);
    return (<OverlayAnimatePresence visible={isOpen} AnimatePresence={AnimatePresence}>
        <StyledMenu {...menuProps} {...typeSelectProps} ref={mergeRef} role="list" {...restProps}>
          {[...state.collection].map((item) => {
            switch (item.type) {
                case 'item':
                    return (<MenuItem StyledMenuItem={StyledMenuItem} key={item.key} item={item} state={state} onAction={props.onAction} onClose={props.onClose} closeOnSelect={closeOnSelect}/>);
                case 'seperator':
                    return (StyledSeparator && (<StyledSeparator {...item.props} key={item.key}/>));
                default:
                    return null;
            }
        })}
        </StyledMenu>
      </OverlayAnimatePresence>);
});

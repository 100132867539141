import React, { memo, useEffect, useMemo, useState } from 'react';
import { Routes } from './routes';

import Settings from './Settings';
import Clients from '../screens/Clients';
import ChatStack from '../screens/Chats';

import SidebarApp from '../components/sidebar/sidebarApp/SidebarApp';

import { RootState, useAppDispatch, useAppSelector } from '@/redux/store';

import { filtersBase, IMPORTANTS } from '@/utils/filters';
import CustomDrawerComponent, { RouteDrawer } from './CustomDrawer/CustomDrawerComponent';
import { setFilters } from '@/redux/actionTypes';
//@ts-expect-error: PusherProvider has no type definitions
import PusherProvider from '../providers/PusherProvider';
import CollpasedProvider from '@/providers/CollpasedProvider';
import { Label } from '@/redux/slices/labels/interfaces';
import { Filters, OrderOptions, platforms } from '@/redux/slices/filters/interfaces';
import { contracts as enumContracts } from '@/redux/slices/filters/interfaces';

import { Channel, Contacts, Team, Teams } from '@/types/typesRedux/interfaces';
import {
  FRAGMENT_CLOSED,
  FRAGMENT_EXPIRED,
  FRAGMENT_FAILED,
  FRAGMENT_OPEN,
  FRAGMENT_PENDING
} from '@/utils/constants';
import { TypeSections } from '@/types/enums';
import { StackScreenProps } from '@react-navigation/stack';
import { RootStackParams } from './interfaces';
import { TABLET_SCREEN } from '@/utils/globales';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { ETypesSections } from './MainRouter';
import HeaderApp from '@/components/header/HeaderApp';

function RouteNavigations(routeNavigation: StackScreenProps<RootStackParams>): React.ReactElement {
  const dispatch = useAppDispatch();
  const teams = useAppSelector((state: RootState) => state.user.teams);
  const teamUser = useAppSelector((state: RootState) => state.user.team?.id);
  const assignees = useAppSelector((state: RootState) => state.contacts.assigneeUsers);
  const contracts = useAppSelector((state: RootState) => state.user.contracts);
  const currentOrder = useAppSelector((state: RootState) => state.filters.currentOrder);
  const labels = useAppSelector((state: RootState) => state.labels.labels);
  const channels = useAppSelector((state: RootState) => state.user.channels);
  const teamId = useMemo(() => routeNavigation?.route?.params?.team, [routeNavigation]);

  const filter = useMemo(
    () => routeNavigation?.route?.params?.filter,
    [routeNavigation?.route?.params?.filter]
  );

  const [assigneeToAddFilters, setAssigneeToAddFilters] = React.useState<Filters[]>([]);
  const [teamName, setTeamName] = useState<string>('');

  const [processedFilters, setProcessedFilters] = React.useState<Filters[]>([]);
  const channelsAvaliables = useMemo(
    () => channels?.filter((item: Channel) => item?.available),
    [channels]
  );

  function findTeamNameById(id: number): string {
    const team = teams?.map((company: Teams) => {
      return findTeam({ teams: company?.teams, id });
    });
    return team ? team[0]?.name : 'TEAM INCORRECTO';
  }

  const findTeam = ({ teams, id }: { teams: Team[]; id: number }) => {
    const team = teams?.find((team: Team) => team?.id == id);
    return team;
  };

  useEffect(() => {
    const selectedTeam = findTeamNameById(teamId);
    setTeamName(selectedTeam);
  }, [teamId]);

  const [isPc] = useMediaQuery([
    {
      minWidth: TABLET_SCREEN
    }
  ]);

  useEffect(() => {
    if (filtersBase && contracts?.length > 0) {
      // const filtersByContract = filtersBase.filter((item) => contracts.includes(item?.contracts));

      let filterChat = [...filtersBase.filter((item) => item?.types === ETypesSections?.chat)];

      let filterClients = [
        ...filtersBase.filter((item) => item?.types === ETypesSections?.clients)
      ];

      // Preparar queries para chat y clientes con la plataforma adecuada
      filterChat = filterChat.map((item) => {
        return {
          ...item,
          query: {
            ...item?.query,
            conversation: {
              ...item?.query?.conversation,
              platform: [platforms?.internal],
              order: currentOrder === OrderOptions?.recent ? false : currentOrder
            }
          }
        };
      });

      filterClients = filterClients.map((item) => {
        return {
          ...item,
          query: {
            ...item?.query,
            conversation: {
              ...item?.query?.conversation,
              platform: channelsAvaliables?.map((item: Channel) => item?.name),
              order: currentOrder === OrderOptions?.recent ? false : currentOrder
            }
          }
        };
      });
      setProcessedFilters([...filterChat, ...filterClients]);
    }
  }, [filtersBase, contracts, channelsAvaliables]);

  useEffect(() => {
    assignees?.length > 0 &&
      setAssigneeToAddFilters(
        assignees?.map((item: Contacts) => ({
          id: item?.id_user,
          name: item?.name,
          idString: item?.id_user.toString(),
          icon: item?.avatar,
          size: 20,
          types: TypeSections?.clients,
          contracts: enumContracts?.chat,
          query: {
            fragment: {
              associations: {
                assignee: [item?.id_user]
              },
              status: [
                FRAGMENT_CLOSED,
                FRAGMENT_EXPIRED,
                FRAGMENT_OPEN,
                FRAGMENT_PENDING,
                FRAGMENT_FAILED
              ]
            },
            conversation: {
              platform: channelsAvaliables?.map((item: Channel) => item?.name),
              group: false,
              updatedAt: null,
              order: currentOrder === OrderOptions?.recent ? false : currentOrder
            },
            filter_name: item?.id_user.toString()
          }
        }))
      );
  }, [assignees]);

  useEffect(() => {
    if (teamUser !== teamId) {
      routeNavigation.navigation.setParams({ team: teamUser });
    }
  }, [teamUser]);

  useEffect(() => {
    if (labels?.length > 0) {
      const filterLabels: Filters[] = labels?.map((item: Label) => ({
        id: item?.id,
        name: item?.tag_name,
        idString: item?.id.toString(),
        icon: item?.id === 136 ? 'star' : 'labels',
        size: 20,
        types: TypeSections?.clients,
        contracts: enumContracts?.chat,
        label: true,
        query: {
          fragment: {
            associations: {
              assignee: []
            },
            status: [
              FRAGMENT_CLOSED,
              FRAGMENT_EXPIRED,
              FRAGMENT_OPEN,
              FRAGMENT_PENDING,
              FRAGMENT_FAILED
            ]
          },
          conversation: {
            platform: channelsAvaliables?.map((item: Channel) => item?.name),
            group: false,
            updatedAt: null,
            labels: [item?.id?.toString()],
            order: currentOrder === OrderOptions?.recent ? false : currentOrder
          },
          filter_name: item?.id?.toString()
        }
      }));

      const labelImportant = filterLabels?.find((item: Filters) => item?.idString === IMPORTANTS);
      let filterConversations = processedFilters;
      filterConversations = [labelImportant, ...filterConversations];

      dispatch(
        setFilters({
          assignee: filterConversations,
          agents: assigneeToAddFilters,
          labels: filterLabels.filter((item) => item?.idString !== IMPORTANTS)
        })
      );
    }
  }, [assigneeToAddFilters, labels, processedFilters]);

  // la funcion handleDeepLink se ejecuta cuando se abre la app desde un link

  const routesSidebarApp: RouteDrawer[] = useMemo(
    () => [
      //TODO descomentar despues que se haga la pantalla de bienvenida
      // {
      //   id: '4',
      //   name: RouteWelcome.welcome,
      //   component: Welcome,

      //   // le paso los parametros de la ruta anterior para que en ChatStack este disponible el team
      //   initialParams: {
      //     team: teamId,
      //     filter
      //   },
      //   options: {
      //     headerShown: true,
      //     headerTitle: translate('navigation.welcome'),
      //     title: translate('navigation.welcome'),
      //     cardStyle: {
      //       backgroundColor: 'transparent'
      //     }
      //   }
      // },
      {
        id: '2',
        name: Routes.clientes,
        component: Clients,
        // le paso los parametros de la ruta anterior para que en ChatStack este disponible el team
        initialParams: {
          team: teamId,
          filter
        },
        options: {
          headerShown: true,
          headerTitle: teamName,
          title: teamName,
          cardStyle: {
            backgroundColor: 'transparent'
          }
        }
      },
      {
        id: '1',
        name: Routes.Chat,
        component: ChatStack,
        // le paso los parametros de la ruta anterior para que en ChatStack este disponible el team
        initialParams: {
          team: teamId
        },
        options: {
          headerShown: true,
          headerTitle: 'Chat',
          title: 'Chat',
          cardStyle: {
            backgroundColor: 'transparent'
          }
        }
      },
      {
        id: '3',
        name: Routes.Settings,
        component: Settings,
        initialParams: {
          team: teamId,
          filter
        },
        options: {
          headerShown: true,
          headerTitle: 'Ajustes',
          title: 'Ajustes',
          cardStyle: {
            backgroundColor: 'transparent'
          }
        }
      }
    ],
    [teamId, filter]
  );

  return (
    <PusherProvider filter={filter}>
      <CollpasedProvider>
        <CustomDrawerComponent
          header={() => <HeaderApp />}
          DrawerSidebar={SidebarApp}
          routes={routesSidebarApp}
          drawePermanent={!!isPc}
          maxWidthSidebar={isPc ? 95 : '100%'}
        />
      </CollpasedProvider>
    </PusherProvider>
  );
}
/**
 * @description Route Navigations es el componente que renderiza los screens chat, clients y settings,
 * tiene el sidebar left y el header app.
 * dentro del sidebar left se renderizan los botones de la barra lateral izquierda y el header app.
 * las rutas de los screens se renderizan en el componente CustomDrawerComponent
 * @param {React.PropsWithChildren<RouteNavigationsProps>} props
 * @property {React.ReactElement} RouteNavigations The RouteNavigations component.
 * @returns {React.ReactElement}
 */
export default memo(RouteNavigations);

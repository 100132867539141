import React, { forwardRef } from 'react';
import { useFormControlProvider, FormControlContext } from './useFormControl';
const FormControl = (StyledFormControlBox) => forwardRef(({ ...props }, ref) => {
    const { htmlProps, ...context } = useFormControlProvider(props);
    const { isDisabled, isInvalid, ...remainingProps } = context;
    return (<FormControlContext.Provider value={context}>
        <StyledFormControlBox ref={ref} {...remainingProps} {...htmlProps} states={{
            disabled: isDisabled,
            invalid: isInvalid,
        }} dataSet={{
            disabled: isDisabled ? 'true' : 'false',
            invalid: isInvalid ? 'true' : 'false',
        }}/>
      </FormControlContext.Provider>);
});
export default FormControl;

import React, { forwardRef } from 'react';
import { useCheckbox } from './CheckboxProvider';
const CheckboxIcon = (StyledCheckboxIcon) => forwardRef(({ children, forceMount = false, ...props }, ref) => {
    const { isChecked } = useCheckbox('CheckboxContext');
    if (forceMount || isChecked) {
        return (<StyledCheckboxIcon {...props} ref={ref}>
          {children}
        </StyledCheckboxIcon>);
    }
    return null;
});
export default CheckboxIcon;

import * as React from 'react';
import DraggableFlatList from 'react-native-draggable-flatlist';
import { Dimensions, StyleSheet } from 'react-native';
import { Spinner, Text, VStack, View } from '@gluestack-ui/themed';
import ItemAutomatization from '../partials/automatizations/ItemAutomatization';
import { Workflow } from './interface';
import AlertComponent from '@/atoms/AlertComponent';
import { RootState, useAppDispatch, useAppSelector } from '@/redux/store/index';
import EmptyState from '../emptystates/EmptyState';
import NewAutomatization from '../partials/automatizations/NewAutomatization';
import { Image } from 'expo-image';
import { Asset } from 'expo-asset';
import { changePriority } from '@/redux/slices/automatization/requests';
import { useLinkss } from '@/providers/LinkProvider';
import translate from '@/helpers/traslations';
import { setWorkflows } from '@/redux/actionTypes/index';

const DraggableList = ({ routeAutomatization }) => {
  const dispatch = useAppDispatch();
  const workflows = useAppSelector((state: RootState) => state?.automatization?.workflows);
  const companyId = useAppSelector((state: RootState) => state.user?.company?.IDEmpresas);
  const teamId = useAppSelector((state: RootState) => state.user.team?.id);
  const idStringWorkflow = useAppSelector(
    (state: RootState) => state.automatization.idStringWorkflow
  );

  const activeChannels = useAppSelector(
    (state: RootState) => state.user?.activeChannels?.[companyId]
  );
  const gettingWorkflows = useAppSelector(
    (state: RootState) => state.automatization.gettingWorkflows
  );

  const [data, setData] = React.useState<Workflow[]>([]);
  const [image, setImage] = React.useState(null);
  const [from, setFrom] = React.useState<number>();
  const { showToast } = useLinkss();

  React.useEffect(() => {
    if (workflows) {
      setData(workflows[idStringWorkflow]);
    }
  }, [workflows, idStringWorkflow]);

  // Precarga la imagen
  React.useEffect(() => {
    const preloadImage = async () => {
      const [{ localUri }] = await Asset.loadAsync(`${require('@/assets/images/empty-data.svg')}`);
      setImage(localUri);
    };
    preloadImage();
  }, []);

  const onDragEnd = React.useCallback(
    ({ data, rest, itemId }) => {
      if (from === rest?.to) {
        return;
      }
      dispatch(
        changePriority({
          id: itemId,
          team_id: teamId,
          currentPriority: rest.from + 1,
          newPriority: rest?.to + 1
        })
      )
        .then(() => {
          showToast({
            subtitle: translate('automatizations.priorityChanged'),
            type: 'success',
            variant: 'accent'
          });
        })
        .catch(() => {
          showToast({
            subtitle: translate('automatizations.errorToChangePriority'),
            type: 'error',
            variant: 'accent'
          });
        });
      // setData(data);
      dispatch(setWorkflows({ idString: idStringWorkflow, workflows: data }));
    },
    [from]
  );

  return (
    <VStack style={styles.container} space="sm">
      <AlertComponent
        action="muted"
        variant="solid"
        idString="automatizations.dragAndDrop"
        idStringTitle="automatizations.dragAndDropTitle"
        translateText
      />

      <DraggableFlatList
        data={data?.length ? data : []}
        renderItem={({ item, drag, isActive }) => (
          <ItemAutomatization
            isActive={isActive}
            item={item}
            drag={drag}
            channels={activeChannels ?? []}
          />
        )}
        keyExtractor={(item, index) => `draggable-item-${item?._id}`}
        onDragBegin={(drag) => setFrom(drag)}
        onDragEnd={({ data, ...rest }) => {
          const itemId = data[rest.to]?._id;
          onDragEnd({ data, rest, itemId });
        }}
        ListHeaderComponent={gettingWorkflows && <Spinner color="$pxsol" />}
        ListEmptyComponent={
          <EmptyState
            idString="messageError.noAutomatizations"
            textSize="md"
            color="secondary"
            image={
              <Image
                alt="empty-state-1"
                source={image}
                style={{
                  width: Dimensions.get('window').width * 0.3,
                  height: Dimensions.get('window').width * 0.3
                }}
              />
            }
          >
            <View>
              <NewAutomatization {...routeAutomatization} />
            </View>
          </EmptyState>
        }
      />
    </VStack>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1
  }
});

export default React.memo(DraggableList);

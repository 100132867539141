import React from 'react';
import { Linking, StyleSheet, TextStyle } from 'react-native';

import { TText } from '@/components/themed';
import { colorsProps, useThemeColor } from '@/hooks/useTheme';
import { Link } from '@gluestack-ui/themed';
import colors from '../../config/colors';

type TextFormatterProps = {
  children: string;
  numberOfLines?: number;
  bold?: boolean;
  color?: colorsProps;
};

const TextFormatter: React.FC<TextFormatterProps> = ({
  children,
  numberOfLines = 0,
  color,
  bold
}) => {
  const linkColor = useThemeColor({ light: colors.light.links, dark: colors.dark.links }, 'links');
  const styls = StyleSheet.create({
    bold: { fontWeight: 'bold' },
    italic: { fontStyle: 'italic' },
    strikethrough: { textDecorationLine: 'line-through' },
    default: {} // Estilo por defecto
  });

  const parseAndRenderText = (text: string) => {
    // Separamos en dos expresiones regulares más simples y específicas
    const markdownLinkRegex = /\[([^\]]+)\]\((?:https?:\/\/|www\.)(?:[^\s()]+)\)/g;
    const plainUrlRegex = /(?:https?:\/\/|www\.)(?:[^\s()[\]]+(?:\([^\s()]*\)[^\s()[\]]*)*)/g;
    const emailRegex = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9.-]+)/g;

    const emailMatches: string[] = [];
    const urlMatches: { label: string; url: string }[] = [];

    // Primero procesamos los links en formato markdown
    text = text.replace(markdownLinkRegex, (match, label, url) => {
      const extractedUrl = match.slice(match.indexOf('(') + 1, -1);
      urlMatches.push({
        label,
        url: extractedUrl.startsWith('www.') ? 'https://' + extractedUrl : extractedUrl
      });
      return ` [[URL${urlMatches.length - 1}]] `;
    });

    // Luego procesamos las URLs planas
    text = text.replace(plainUrlRegex, (match) => {
      const url = match.startsWith('www.') ? 'https://' + match : match;
      urlMatches.push({ label: match, url });
      return ` [[URL${urlMatches.length - 1}]] `;
    });

    text = text.replace(emailRegex, (match) => {
      emailMatches.push(match);
      return ` [[EMAIL${emailMatches.length - 1}]] `; // Marcador EMAIL
    });

    // Dividir el texto usando los marcadores y respetar saltos de línea
    const parts = text.split(/(\[\[URL\d+\]\]|\[\[EMAIL\d+\]\]|\n)/g);

    return parts
      .map((part, index) => {
        // Manejar saltos de línea
        if (part === '\n') {
          return (
            <TText key={index} style={{ lineHeight: 1 }}>
              {'\n'}
            </TText>
          ); // Salto de línea
        }

        // Verificar si es un marcador de URL o correo
        if (part.startsWith('[[URL') && part.endsWith(']]')) {
          const urlIndex = parseInt(part?.match(/\d+/)?.[0] ?? '0', 10); // Extraer índice
          const { label, url } = urlMatches[urlIndex];
          return (
            <Link
              onPress={() => Linking.openURL(url)}
              key={index}
              style={[styls.default, { color: linkColor } as TextStyle]}
            >
              {' '}
              {label}{' '}
            </Link>
          );
        }

        if (part.startsWith('[[EMAIL') && part.endsWith(']]')) {
          const emailIndex = parseInt(part?.match(/\d+/)?.[0] ?? '0', 10); // Extraer índice
          return (
            <TText
              lineBreakMode="clip"
              ellipsizeMode="head"
              numberOfLines={numberOfLines}
              key={index}
              style={styls.default} // Sin estilos
              color={color ?? 'text'}
            >
              {`${emailMatches[emailIndex]}`}{' '}
            </TText>
          );
        }

        // Para partes de texto normal, aplicar estilos
        const formattedParts = part
          .split(/(\*[^*]*\*|_[^_]*_|~[^~]*~)/g)
          .map((subPart, subIndex) => {
            let textStyle: TextStyle = styls.default;

            // Determinar el estilo y eliminar los caracteres de formato
            if (subPart.startsWith('*') && subPart.endsWith('*')) {
              textStyle = styls.bold;
              subPart = subPart.slice(1, -1); // Eliminar asteriscos
            } else if (subPart.startsWith('_') && subPart.endsWith('_')) {
              textStyle = styls.italic;
              subPart = subPart.slice(1, -1); // Eliminar guiones bajos
            } else if (subPart.startsWith('~') && subPart.endsWith('~')) {
              textStyle = styls.strikethrough;
              subPart = subPart.slice(1, -1); // Eliminar tildes
            }

            // Devolver el componente de texto con estilo
            return (
              <TText
                lineBreakMode="clip"
                ellipsizeMode="head"
                numberOfLines={numberOfLines}
                key={`${index}-${subIndex}`} // Asegurar clave única
                style={textStyle}
                color={color ?? 'text'}
              >
                {subPart.trimStart()}
              </TText>
            );
          });

        return formattedParts; // Devolver todas las partes formateadas
      })
      .filter((part) => part); // Filtrar partes vacías
  };

  return (
    <TText
      lineBreakMode="clip"
      ellipsizeMode="head"
      numberOfLines={numberOfLines}
      selectable
      color={color ?? 'text'}
      style={{
        fontWeight: bold ? 'bold' : 'normal'
      }}
    >
      {typeof children === 'string' ? parseAndRenderText(children) : children}
    </TText>
  );
};

export default React.memo(TextFormatter, (prevProps, nextProps) => {
  return prevProps.children === nextProps.children;
});

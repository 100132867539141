export function composeEventHandlers(...args) {
    return function handleEvent(event) {
        try {
            for (let i = 0; i < args.length; i++) {
                args[i]?.(event);
            }
        }
        catch (e) {
            //
        }
    };
}
